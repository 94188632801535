// 存在チェック
export function isInvalidPresence(value: string): boolean {
  if (!value.length) return true;
  return false;
}
// maxlengthチェック
export function isInvalidLength(value: string, maxlength: number): boolean {
  if (value.length > maxlength) return true;
  return false;
}
// 選択肢チェック
export function isInvalidChoice(value: string, choices: Array<String>): boolean {
  return !choices.includes(value);
}
// メールアドレス形式チェック
export function isInvalidEmail(value: string): boolean {
  if (!value.match(/.+@.+\..+/)) return true;
  return false;
}
// 日本語10文字バリデーション
export function isInvalidJapanese(value: string): boolean {
  if (!value.match(/[ぁ-ゖァ-ヶ「」ー～〜、。・￥／＼（）！？＠：※々…〒↑→↓←一-龠]{10,}/)) return true;
  return false;
}