import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import FirstView from './FirstView';
import Works from './Works';
import Showreel from './Showreel';
import News from './News';
import Contact from './Contact';
import { useEffect } from 'react';
import useBackground from 'hooks/useBackground'
import { ISOvserver } from "modules/modules"

type Props = {
}
export default function Index(props: Props) {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer.disconnect();
    };
  }, []);

  // 戻るボタンが押されたら背景をdeactivate
  useEffect(() => {
    window.addEventListener('popstate', () => { background.deactivate() })
    return () => {
      window.removeEventListener('popstate', () => { background.activate() })
    }
  }, [])



  return (
    <>
      <Helmet>
        <title>WiLDCARD Inc.｜映像制作の「万能の手札」。</title>
        <meta name="description" content="アーティストライブ映像、TVCMの映像制作を手掛ける映像制作会社、株式会社WiLDCARD。アニメーション、VFX、3DCGなど幅広い映像に対応。映像制作に関するご相談、お待ちしております。" />
        <meta property="og:url" content="https://wildcard.jp/" />
        <meta property="og:title" content="WiLDCARD Inc." />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__home">
          <FirstView />
          <Works />
          <Showreel />
          <News />
          <Contact />
        </main>
      </motion.div>
    </>
  );
}