import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from 'react';
import { qs, getHeaderHeight, scrollTo, ISOvserver } from '../../../modules/modules'
import { isInvalidPresence, isInvalidLength, isInvalidChoice, isInvalidEmail, isInvalidJapanese } from '../../../modules/validate'
import useBackground from '../../../hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'

type Props = {
}

const Index = (props: Props) => {

  let hasError: boolean = false;
  let errorInputs: Array<String> = [];

  const navigate = useNavigate();

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer.disconnect();
    };
  }, []);

  /*=======================================
    お問い合わせフォーム用
  =======================================*/
  const [errorExistenseMessage, seterrorExistenseMessage] = useState("");
  const [errorInputMessages, seterrorInputMessages] = useState([]);
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [detailsErrorMessage, setDetailsErrorMessage] = useState("");
  const [agreementErrorMessage, setAgreementErrorMessage] = useState("");

  const radio_categories: Array<String> = [
    "制作を依頼したい",
    "まずは見積もりをお願いしたい",
    "その他"
  ]

  function validateOrganization(value: string) {
    let msg = [];
    if (isInvalidLength(value, 50)) {
      msg.push("50文字以内でご入力ください。");
      hasError = true;
      pushErrorInputs("組織名")
    };
    setOrganizationErrorMessage(joinedErrMsg(msg));
  }

  function validateName(value: string) {
    let msg = [];
    if (isInvalidPresence(value)) {
      msg.push("お名前をご入力ください。");
      hasError = true;
      pushErrorInputs("お名前")
    };
    if (isInvalidLength(value, 50)) {
      msg.push("50文字以内でご入力ください。");
      hasError = true;
      pushErrorInputs("お名前")
    };
    setNameErrorMessage(joinedErrMsg(msg));
  }

  function validateEmail(value: string) {
    let msg = [];
    if (isInvalidPresence(value)) {
      msg.push("メールアドレスをご入力ください。");
      hasError = true;
      pushErrorInputs("メールアドレス")
    } else {
      // 存在した場合のみ
      if (isInvalidLength(value, 256)) {
        msg.push("256文字以内でご入力ください。");
        hasError = true;
        pushErrorInputs("メールアドレス")
      };
      if (isInvalidEmail(value)) {
        msg.push("メールアドレスの形式をご確認ください。");
        hasError = true;
        pushErrorInputs("メールアドレス")
      };
    }
    setEmailErrorMessage(joinedErrMsg(msg));
  }

  function validateCategory(value: string) {
    let msg = [];
    if (value.length && isInvalidChoice(value, radio_categories)) {
      msg.push("選択肢からお選びください。");
      hasError = true;
      pushErrorInputs("お問い合わせの種類")
    };
    setCategoryErrorMessage(joinedErrMsg(msg));
  }

  function validateDetails(value: string) {
    let msg = [];
    if (isInvalidPresence(value)) {
      msg.push("詳しいお問い合わせ内容をご入力ください。");
      hasError = true;
      pushErrorInputs("詳しいお問い合わせ内容")
    } else {
      if (isInvalidJapanese(value)) {
        msg.push("スパム対策のため、最低10文字以上の連続した日本語をご入力ください。");
        hasError = true;
        pushErrorInputs("詳しいお問い合わせ内容");
      };
      if (isInvalidLength(value, 1000)) {
        msg.push("1000文字以内でご入力ください。");
        hasError = true;
        pushErrorInputs("詳しいお問い合わせ内容")
      };
    }
    setDetailsErrorMessage(joinedErrMsg(msg));
  }

  function validateAgreement(checked: string) {
    let msg = [];
    if (!checked) {
      msg.push("プライバシーポリシーへのご同意をお願いします。");
      hasError = true;
      pushErrorInputs("プライバシーポリシー")
    };
    setAgreementErrorMessage(joinedErrMsg(msg));
  }
  function joinedErrMsg(msg: Array<String>) {
    return msg.join("また、");
  }
  function pushErrorInputs(msg: string) {
    if (!errorInputs.includes(msg)) errorInputs.push(msg);
  }

  function encode(data: any) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e: any) {

    e.preventDefault();

    hasError = false;
    errorInputs = [];

    let organizationValue = qs("#organization").value;
    validateOrganization(organizationValue);

    let nameValue = qs("#name").value;
    validateName(nameValue);

    let emailValue = qs("#email").value;
    validateEmail(emailValue);

    let categoryInput = qs(".category_radio:checked");
    let categoryValue = categoryInput ? categoryInput.value : "";
    validateCategory(categoryValue);

    let detailsValue = qs("#details").value;
    validateDetails(detailsValue);

    let agreementValue = qs("#agreement").checked;
    validateAgreement(agreementValue);

    if (hasError) {
      seterrorExistenseMessage("入力内容をご確認ください。");
      seterrorInputMessages(errorInputs);

      let target = qs("#js_err_existence");
      let offset = window.scrollY + target.getBoundingClientRect().top - getHeaderHeight();
      scrollTo(offset);

      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", "organization": organizationValue, "name": nameValue, "email": emailValue, "category": categoryValue, "details": detailsValue })
    })
      .then(response => {
        background.deactivate()
        navigate('submitted')
      })
      .catch(function (error) {
        alert("お問い合わせの送信に失敗しました。恐れ入りますが、しばらく時間を置いてもう一度お試しください。");
      })
  };

  // テキストエリア自動伸縮
  function handleTextareaInput(e: any) {
    let offset = 16
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight + offset}px`;
  }

  return (
    <>
      <Helmet>
        <title>CONTACT｜WiLDCARD Inc.</title>
        <meta name="description" content={"お問い合わせ｜映像制作の「万能の手札」。アーティストライブ映像、TVCMの映像制作を手掛ける映像制作会社、株式会社WiLDCARD。アニメーション、VFX、3DCGなど幅広い映像に対応。映像制作に関するご相談、お待ちしております。"} />
        <meta property="og:url" content={"https://wildcard.jp/contact/"} />
        <meta property="og:title" content={"CONTACT｜WiLDCARD Inc."} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >

        <main id="p__contact" className="l__content_width">

          <PrimaryHeading letter="CONTACT" letter_ja="お問い合わせ" />

          <div className="contact_inner c__js_fade">

            <p className="contact_description c__common_text">
              <span className="c__common_text">映像制作に関することでしたら、どんなことでもお気軽にお問い合わせください。</span>
              <span className="c__common_text">お見積もりのご依頼や、どんな映像が効果的なのかといったご相談でも構いません。</span>
              <span className="c__common_text">皆様からのご連絡をお待ちしております。</span>
            </p>


            <form name="contact" method="post">

              <p id="js_err_existence" className="c__error_message">{errorExistenseMessage}<br />
                {errorInputMessages.map((error: any, index: number) => {
                  return (
                    <span key={index} className="u__error_list">{"・" + error}</span>
                  )
                })}
              </p>

              <div className="input_wrapper">
                <label className="c__contact_label required">お名前</label>
                <input type="text" id="name" name="name" className="c__contact_textbox" maxLength={60} placeholder="例）佐藤一郎" />
                <p id="nameErrorMessage" className="c__error_message">{nameErrorMessage}</p>
              </div>

              <div className="input_wrapper">
                <label className="c__contact_label">組織名</label>
                <input type="text" id="organization" name="organization" className="c__contact_textbox" maxLength={50} placeholder="例）株式会社WiLDCARD" />
                <p id="organizationErrorMessage" className="c__error_message">{organizationErrorMessage}</p>
              </div>

              <div className="input_wrapper">
                <label className="c__contact_label required">メールアドレス</label>
                <input type="email" id="email" name="email" className="c__contact_textbox" maxLength={254} placeholder="例）xxx@xxx.com" data-conv-half-alphanumeric="true" />
                <p id="emailErrorMessage" className="c__error_message">{emailErrorMessage}</p>
              </div>

              <div className="input_wrapper">
                <p className="c__contact_label required">概要</p>
                <label className="c__contact_select">
                  <select className="" name="category">
                    {radio_categories.map((category: any, index: number) => {
                      return (
                        <option key={index} id={"c" + index} value={category} className="">{category}</option>
                      )
                    })}
                  </select>
                </label>
                <p id="categoryErrorMessage" className="c__error_message">{categoryErrorMessage}</p>
              </div>

              <div className="input_wrapper">
                <label className="c__contact_label required">詳しいお問い合わせ内容</label>
                <textarea id="details" name="details" className="c__contact_textarea" maxLength={1000} placeholder="詳しいお問い合わせ内容をご入力ください。" onInput={(e) => handleTextareaInput(e)} ></textarea>
                <p id="detailsErrorMessage" className="c__error_message">{detailsErrorMessage}</p>
              </div>

              <div className="agreement_wrapper input_wrapper">
                <p className="agreement_notice">
                  <span className="u__ib">お問い合わせフォームをご利用の際は、</span>
                  <span className="u__ib"><a className="c__external_link" href="/terms" target="_blank" rel="noopener noreferrer" >プライバシーポリシー</a>を</span>
                  <span className="u__ib">ご一読ください。</span>
                </p>
                <input type="checkbox" id="agreement" name="agreement" value="agreed" className="c__contact_checkbox" />
                <label htmlFor="agreement" >同意する</label>
                <p id="agreementErrorMessage" className="c__error_message">{agreementErrorMessage}</p>
              </div>

              <button className="c__secondary_button" type="submit" id="submit" onClick={handleSubmit} >送信する</button>

            </form>
          </div>
        </main>


      </motion.div>
    </>
  );
}
export default Index;