import { useEffect, } from "react";
import { qs, ISOvserve, removeClass, addClass, initParallax } from "modules/modules"
import fv_img from "assets/img/home/fv.png";
import fv from "assets/img/home/reel.mp4";
import YoutubeModal from "components/layouts/videos/YoutubeModal";
import { useModal } from 'react-hooks-use-modal';

type Props = {
}
export default function FirstView(props: Props) {

  /*=======================================
    モーダル制御
  =======================================*/
  const [Modal, modalOpen, modalClose, isOpen] = useModal('root', {
    preventScroll: true
  });

  function handleModalOpen() {
    const video = qs("#fv_video");
    removeClass(video, "active");
    modalOpen();
    video.pause();
  }
  function handleModalClose() {
    const video = qs("#fv_video");
    video.play();
    addClass(video, "active");
    modalClose();
  }

  useEffect(() => {
    const cleanupParallax = initParallax();
    const targetSelector = "#first_view";
    const fv = qs(targetSelector);
    const options = {
      rootMargin: "-70% 0px 0px 0px",
    };
    const observer = ISOvserve(
      targetSelector,
      options,
      () => { removeClass(fv, "disabled") },
      () => { addClass(fv, "disabled") }
    )

    const video = qs("#fv_video");
    const img = qs("#fv_img");
    const fv_play_button = qs("#fv_play_button");
    video.addEventListener('loadedmetadata', handleMovieLoaded)

    function handleMovieLoaded() {
      setTimeout(() => {
        addClass(video, "active")
        addClass(img, "disabled")
        addClass(fv_play_button, "active")
        video.play();
      }, 500);
    }

    return () => {
      cleanupParallax();
      observer.disconnect();
      video.removeEventListener('loadedmetadata', handleMovieLoaded)
    }
  }, []);



  return (
    <>
      <div id="first_view" className="">
        <div id="fv_movie_wrapper" className="js__parallax_container js__parallax_magnif_2">
          <img id="fv_img" className="" src={fv_img} alt="" />
          { /* 以下の属性を指定しvideo.play()することによって自動再生できる */}
          <video id="fv_video" className="" src={fv} muted loop playsInline autoPlay></video>
          <div id="fv_play_button" onClick={handleModalOpen}></div>
        </div>
        <h2 className="company_name">WiLDCARD Inc.</h2>
        <h3 className="business_domain">Movies / Motion Graphics / VFX</h3>
        <div className="scrolldown">Scroll</div>
      </div>
      <YoutubeModal modal={Modal} handleModalClose={handleModalClose} videoUrl="https://www.youtube.com/watch?v=ptUexxIiysA&ab_channel=WiLDTIPS" />
    </>
  );
}
