import NewsList from '../../models/news/NewsList'
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import { ISOvserver } from '../../../modules/modules'
import { useMemo, useEffect } from 'react';
import useBackground from '../../../hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'

type Props = {
}

export default function Index(props: Props) {

  const background = useBackground();

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer.disconnect();
    };
  }, []);

  const index = useMemo(() => {
    return (
      <>
        <Helmet>
          <title>NEWS｜WiLDCARD Inc.</title>
          <meta name="description" content="NEWS一覧｜映像制作の「万能の手札」。アーティストライブ映像、TVCMの映像制作を手掛ける映像制作会社、株式会社WiLDCARD。アニメーション、VFX、3DCGなど幅広い映像に対応。映像制作に関するご相談、お待ちしております。" />
          <meta property="og:url" content="https://wildcard.jp/news" />
          <meta property="og:title" content="NEWS｜WiLDCARD Inc." />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }} // このページに遷移した直後
          animate={{ opacity: 1 }} // 最終的な状態
          exit={{ opacity: 0 }} // 離れる際にどうなるか
          transition={{ duration: 0.5 }} // 切り替えに要する時間
        >
          <main id="p_news_index" className="l__content_width">
            <PrimaryHeading letter="NEWS" letter_ja="お知らせ" />
            <NewsList limit={12} pagenate={true} />
          </main>
        </motion.div>
      </>
    );
  }, [])

  return (
    <>
      {index}
    </>
  );

}